import { useState } from "react"
import AppHeader from "../../components/admin/AppHeader"
import AppSidebar from "../../components/admin/AppSidebar"
import AdminSeeDashboard from "../../components/admin/AdminSeeDashboard"

const Admin = ({setAuthRole}) => {
    let [openMenu, setOpenMenu] = useState(false)
 
  return (
    <div className="w-screen flex">
        <div className={`${openMenu?'hidden':'flex w-[20%] '} `}>
        <AppSidebar/>
           
        </div>
        <div className={`flex flex-col ${openMenu? 'w-full':' w-[80%]'} max-h-screen overflow-hidden `}>
      <AppHeader  setOpenMenu={setOpenMenu}/>
      <div className=" overflow-y-auto max-h-full px-2">
      <AdminSeeDashboard/>
      </div>
  
    </div>
  </div>
  )
}

export default Admin