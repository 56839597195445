import { Link, useNavigate, useParams } from "react-router-dom";
import LoginImage from "../assets/images/login-image.jpg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET, POST } from "../utils/webService";
import { BeatLoader } from "react-spinners";
import {
  API_GET_USER_EMAIL,
  API_POST_SUBMIT_USER_DETAILS,
} from "../utils/APIurls";
import companyLogo from "../assets/images/company-logo.svg";
const UserData = () => {
  const [password, setPassword] = useState("");
  const [userDetail, setUserDetail] = useState({});
  const [userData, setUserData] = useState({});
  const [userInput, setuserInput] = useState({
    email: "",
    password: "",
  });
  let [loading, setLoading] = useState(false);

  const router = useParams();
  const navigate = useNavigate();
  const signUp = (e) => {
    setuserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  // api for sumbit user details
  const signhUpHandler = async (e) => {
    e.preventDefault();

    if (userInput.password !== password) {
      toast.error("password did not match !");
    } else if (userInput?.mobileNo?.length < 10) {
      toast.error("mobile number must be 10 digits !");
    } else {
      setLoading(true);
      POST(API_POST_SUBMIT_USER_DETAILS, {
        fname: userDetail.fname,
        lname: userDetail.lname,
        mobileNo: userDetail.mobileNo,
        email: userInput.email,
        password: userInput.password,
      })
        .then(({ data }) => {
          if (data) {
            toast.success(data.msg);
            setUserData(data);
            navigate("/user/login");
          }
        })
        .catch((err) => {
          toast.error(err.response.data?.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // api for getting email detail
  const optId = router?.id;
  useEffect(() => {
    GET(API_GET_USER_EMAIL(optId))
      .then(({ data }) => {
        setUserDetail(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.history.pushState(null, "", router.asPath);
    window.addEventListener("popstate", function (event) {
      this.history.pushState(null, "", router.asPath);
    });
  }, [router.asPath]);
  return (
    <div className=" relative">
      <section className="bg-gray-50 overflow-hidden">
        <div className="flex  items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 overflow-y-auto">
          <div className="hidden md:block">
            <img src={LoginImage} alt="login" />
          </div>
          <div className="w-full bg-white rounded-lg shadow my-8 ">
            <div className="p-6 space-y-4  md:space-y-4 sm:p-8  ">
              <div className="flex flex-wrap justify-center">
                <img
                  src={companyLogo}
                  alt="company-logo"
                  height={70}
                  width={70}
                />
              </div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                User Details
              </h1>

              <form onSubmit={signhUpHandler} className="space-y-2">
                <div className="flex justify-between gap-2">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="fname"
                      className="bg-purple-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      value={userDetail.fname}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Last name
                    </label>
                    <input
                      type="text"
                      name="lname"
                      className="bg-purple-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      value={userDetail.lname}
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    name="mobileNo"
                    id="mobileNo"
                    className="bg-purple-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5"
                    value={userDetail.mobileNo}
                    disabled
                  />
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Your Email
                  </label>
                  <input
                    onChange={signUp}
                    type="email"
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Password
                  </label>
                  <input
                    onChange={signUp}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 "
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Confirm password
                  </label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                    required
                  />
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 "
                      required
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label className="font-light text-gray-500 ">
                      I accept the{" "}
                      <Link className="font-medium text-primary-600 hover:underline">
                        Terms and Conditions
                      </Link>
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {loading && (
        <div className=" overflow-hidden border-2  absolute w-screen h-screen bg-gray-400 opacity-75 flex justify-center items-center top-1 z-10">
          <BeatLoader size={15} />
        </div>
      )}
    </div>
  );
};

export default UserData;
