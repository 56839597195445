
export const  inputData = [
   {value: 'Channel Finance'},
    {value:'Vender Finance'},
    {value:'Sale'},
    {value:'Invoice Discounding'},
   {value: 'Purchase'},
    {value:'factering'},
    {value:'reverse factering'},
    {value:'export Factor'},
    {value:'import Factor'},
    {value:'Bussiness Loan'},
    {value:'others'}
]