
import SideBar from "../../components/navigationBar/SideBar";
import ReportDashboard from "../../components/userdashboard/ReportDashboard";
const Reports = () => {
  return (
   <div className=" w-screen max-h-screen overflow-hidden">
    <div className="flex">
    <div className=" w-0 md:w-[20%] max-h-screen bg-[#243665] shadow-md border-r-2 border-gray-100">
       <SideBar/>
    </div>
    <div className="w-full md:w-[80%] mt-16 md:px-3 md:py-3 ">
      
    <ReportDashboard/>

    </div>
   </div>
   </div>

  )
}

export default Reports