import { useEffect, useState } from "react";
import otpverifyImage from "../assets/images/login-image.jpg";
import { useNavigate } from "react-router-dom";
import { PATCH, POST } from "../utils/webService";
import Modal from "react-modal";
import {
  API_CHANGE_PASSWPORD,
  APT_POST_VERIFY_OTP,
  SEND_OTP_MOBILE,
} from "../utils/APIurls";
import { toast } from "react-toastify";
import companyLogo from "../assets/images/company-logo.svg";
const ForgotPass = () => {
  const [otp, setOtp] = useState({
    otp: "",
  });
  const [openOtp, setOpenOtp] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [optData, setoptData] = useState(null);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    conform_pass: "",
  });
  const navigate = useNavigate();
  window.onload = function () {
    navigate("/user/forgot-password");
  };
  const otpInput = (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  const passwordHandler = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      //   enableButton();
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const sendOtpToMobile = (e) => {
    e.preventDefault();

    POST(SEND_OTP_MOBILE, { mobileNo: mobileNo })
      .then(({ data }) => {
        if (data?.msg === "verify your OTP") {
          setoptData(data?.otpId);
          setOpenOtp(true);
          setCountdown(60);
        }
        toast.success(data.msg);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const verifyOtphandler = (e) => {
    e.preventDefault();

    POST(APT_POST_VERIFY_OTP, { otpId: optData, otp: otp?.otp })
      .then(({ data }) => {
        if (data.msg === "OTP has been verifield!") {
          toast.success(data.msg);
          setIsOpen(true);
          //   navigate(`/user/forgot-password/${router?.id}`);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const changePassSubmit = (e) => {
    e.preventDefault();
    if (password?.password !== password?.conform_pass) {
      toast.error("password did not match !");
    } else {
      PATCH(API_CHANGE_PASSWPORD, {
        mobileNo: mobileNo,
        password: password?.password,
      })
        .then(({ data }) => {
          setIsOpen(false);
          toast.success(data.msg);
          navigate("/user/login");
        })
        .catch((err) => {
          toast.error(err.response.data.msg);
        });
    }
  };
  const customStyles = {
    content: {
      //   width: "330px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <>
      <div className="flex  items-center px-8 bg-white py-8 mx-auto ">
        <div className="hidden md:block">
          <img className="" src={otpverifyImage} alt="otpImage" />
        </div>
        <div className="w-full bg-white rounded-lg shadow  ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <div className="flex flex-wrap justify-center">
              <img
                src={companyLogo}
                alt="company-logo"
                height={70}
                width={70}
              />
            </div>
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Verify OTP
            </h1>
            {!openOtp ? (
              <form
                onSubmit={sendOtpToMobile}
                className="space-y-4 md:space-y-6"
              >
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Phone Number
                  </label>
                  <input
                    onChange={(e) => setMobileNo(e.target.value)}
                    type="text"
                    pattern="^\d{10}$"
                    name="mobileNo"
                    maxLength={10}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Enter Phone Number"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full cursor-pointer text-white bg-[#a065c7] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Send OTP
                </button>
              </form>
            ) : (
              <form
                onSubmit={verifyOtphandler}
                className="space-y-4 md:space-y-6"
              >
                {countdown > 0 && (
                  <p className="text-[12px] text-green-500 ">
                    One-Time Password (OTP) has sent to your Mobile number{" "}
                    <span className="text-sm"> {countdown} </span>
                  </p>
                )}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    OTP
                  </label>
                  <input
                    onChange={otpInput}
                    type="text"
                    name="otp"
                    maxLength={6}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="00567"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className={`w-full text-white ${
                    countdown !== 0 ? "bg-blue-600" : "bg-gray-300"
                  } hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center `}
                  disabled={countdown === undefined || countdown === 0}
                >
                  Verify OTP
                </button>
                <button
                  onClick={() => setOpenOtp(false)}
                  type="button"
                  className={`w-full text-white ${
                    countdown === undefined || countdown === 0
                      ? "bg-blue-600"
                      : "bg-gray-300"
                  } hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                  disabled={countdown > 1}
                >
                  Resend Otp{" "}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <div className="relative  ">
        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
          className="absolute !w-80 md:!w-96, lg:!w-[500px] top-1 left-1/2 right-auto border border-gray-400 p-4 bg-inherit "
        >
          <div>
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Update Password
            </h1>
            <form onSubmit={changePassSubmit}>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                  Password
                </label>
                <input
                  onChange={passwordHandler}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 "
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 mt-2 ">
                  Confirm password
                </label>
                <input
                  type="text"
                  onChange={passwordHandler}
                  // value={password?.password}
                  name="conform_pass"
                  placeholder=" Enter Confirm Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  required
                />
              </div>
              <button
                type="submit"
                className={`w-full text-white
                  bg-blue-600
                 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4`}
              >
                Update Password
              </button>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ForgotPass;
