import { useEffect, useState } from "react";
import ProfileImage from "../../assets/images/profile.png";
import { GET } from "../../utils/webService";
import { API_GET_SINGLE_DATA } from "../../utils/APIurls";
const ProfileDashboard = () => {
  const [userData, setUserData] = useState({});

  const code = sessionStorage.getItem("code");
  useEffect(() => {
    GET(API_GET_SINGLE_DATA(code))
      .then(({ data }) => {
        setUserData(data?.user);
      })
      .catch((err) => err);
  }, []);

  return (
    <section className=" flex font-medium items-center justify-center">
      <section className=" w-72 md:w-96 mx-auto bg-[#243665] rounded-2xl px-8 py-6 ">
        <div className="mt-6 w-fit mx-auto">
          <img
            src={ProfileImage}
            className="rounded-full w-28 "
            alt="profilePicture"
          />
        </div>

        <div className="mt-8 ">
          <h2 className="text-white font-bold text-2xl tracking-wide">
            {userData?.fname} <br /> {userData?.lname}
          </h2>
        </div>
        <div className="flex flex-wrap  justify-between items-center text-white">
          <p className="text-emerald-400 font-semibold mt-2.5">
            {userData?.email}
          </p>

          <p>{userData?.mobileNo}</p>
        </div>
        <div className=" flex justify-between mt-3 text-white text-sm">
          <p>codes: {userData.code}</p>
          <p>SBI000876534</p>
        </div>

        <div className="h-1 w-full bg-black mt-8 rounded-full">
          <div className="h-1 rounded-full w-full bg-yellow-500 "></div>
        </div>
      </section>
    </section>
  );
};

export default ProfileDashboard;
