import { useEffect, useState } from "react";
import { API_GET_ONE_USER_ALL_OFFERS } from "../../utils/APIurls";

import { GET } from "../../utils/webService";

const TdStyle = {
  ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-2 px-2  font-semibold text-sm text-white`,
  TdStyle: `border-b  border-[#E8E8E8] bg-[#F3F6FF] py-2 px-2 text-center  font-normal text-xs text-dark`,
  TdButton: ` inline-block px-6 py-2   text-green-500 `,
  TdButton2: `   inline-block px-6 py-2    text-yellow-500 `,
  TdButton3: `  inline-block px-6 py-2    text-red-500 `,
};

const ReportDashboard = () => {
  const [shaveAllOffer, setShaveAllOffer] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const code = sessionStorage.getItem("code");
  // let page =  pageCount
  let limit = 8;
  useEffect(() => {
    GET(API_GET_ONE_USER_ALL_OFFERS(code, page, limit)).then(({ data }) => {
      if (data) {
        setShaveAllOffer(data?.allOffers);
        setCount(data.count);
      }
    });
  }, [page]);

  return (
    <>
      <section className="bg-gray-200 overflow-x-auto max-h-full  rounded-md">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full ">
              <div className="max-w-full ">
                <table className="w-full table-auto">
                  <thead className="text-center bg-[#243665]">
                    <tr>
                      <th className={TdStyle.ThStyle}> Date</th>
                      <th className={TdStyle.ThStyle}> Company </th>
                      <th className={TdStyle.ThStyle}> Email </th>
                      <th className={TdStyle.ThStyle}> PAN </th>
                      <th className={TdStyle.ThStyle}>GST </th>
                      <th className={TdStyle.ThStyle}>Contact</th>
                      <th className={TdStyle.ThStyle}>Amount</th>
                      <th
                        className={`w-1/6 min-w-[200px] border-l border-transparent text-xs px-2 py-2 font-semibold text-white `}
                      >
                        Product Requested
                      </th>
                      <th className={TdStyle.ThStyle}>Credit Rating</th>
                      <th className={TdStyle.ThStyle}>Turn Over</th>
                      <th className={TdStyle.ThStyle}>State</th>
                      <th className={TdStyle.ThStyle}>City</th>
                      <th className={TdStyle.ThStyle}>Address</th>
                      <th className={TdStyle.ThStyle}>Status</th>
                    </tr>
                  </thead>
                  {shaveAllOffer &&
                    shaveAllOffer.map((el, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td className={TdStyle.TdStyle}>
                              {el?.createdAt.split("T")[0]}
                            </td>
                            <td className={TdStyle.TdStyle}>
                              {el.companyName}
                            </td>
                            <td className={TdStyle.TdStyle}>{el.email}</td>
                            <td className={TdStyle.TdStyle}>{el.panNumber}</td>
                            <td className={TdStyle.TdStyle}>
                              {el.gstNumber ? el.gstNumber : "NA"}
                            </td>
                            <td className={TdStyle.TdStyle}>
                              {el.contactNumber}
                            </td>
                            <td className={TdStyle.TdStyle}>{el.amount}</td>
                            <td className={TdStyle.TdStyle}>
                              {el.productRequested}
                            </td>
                            <td className={TdStyle.TdStyle}>
                              {el.credit_retting}
                            </td>
                            <td className={TdStyle.TdStyle}>{el.turn_over}</td>
                            <td className={TdStyle.TdStyle}>{el.state}</td>
                            <td className={TdStyle.TdStyle}>{el.city}</td>
                            <td className={TdStyle.TdStyle}>{el.address}</td>
                            <td className={TdStyle.TdStyle}>
                              {el.status === "pending" ? (
                                <div
                                  // type="button"
                                  className={`${TdStyle.TdButton2} `}
                                >
                                  {el.status}
                                </div>
                              ) : el.status === "approved" ? (
                                <div
                                  // type="button"
                                  className={`${TdStyle.TdButton} `}
                                >
                                  Approved
                                </div>
                              ) : (
                                <div
                                  // type="button"
                                  className={`${TdStyle.TdButton3} `}
                                >
                                  Reject
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center bg-[#F3F6FF] py-2 text-xs">
        <div className="  w-fit justify-center border rounded-l-md rounded-r-md  ">
          <button
            disabled={page === 1}
            className={`px-3 py-2 border-x rounded-l-md ${
              page === 1 ? "bg-gray-400" : "hover:bg-blue-400"
            } `}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <button className="px-3 py-2 border-x ">
            {page}
            {` of  ${Math.ceil(count / limit)}`}
          </button>
          <button
            disabled={page === Math.ceil(count / limit)}
            className={`px-3 py-2 border-x rounded-r-md ${
              page === Math.ceil(count / limit)
                ? "bg-gray-400 "
                : "hover:bg-blue-400"
            }`}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ReportDashboard;
