import { NavLink, useNavigate } from "react-router-dom";

import {
  CDropdown,
  CDropdownToggle,
  CAvatar,
  CDropdownMenu,
  CDropdownHeader,
  CDropdownItem,
  CHeaderNav,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilAccountLogout } from "@coreui/icons";
import Logo from "../../assets/images/profile.png";

import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";

const AppHeader = ({ setOpenMenu }) => {
  const navigate = useNavigate();
  const adminData = sessionStorage.getItem("name");
  const adminLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("code");
    navigate("/user/login");
  };

  return (
    <div className="py-[12px] flex shadow-md">
      <div className="flex justify-between  w-full  px-4">
        <div className=" flex gap-4 items-center">
          <div onClick={() => setOpenMenu((prev) => !prev)}>
            <AiOutlineMenu size={"24px"} />
          </div>
          <NavLink className="no-underline text-gray-500" to="/admin">
            Dashboard
          </NavLink>
          <NavLink className="no-underline text-gray-500" to="/Allconnecters">
            Connectors
          </NavLink>
          <NavLink
            className="no-underline text-gray-500"
            to="/list-Of-All-proposal"
          >
            Proposal
          </NavLink>
        </div>

        <div className="flex gap-4 items-center">
          <div>
            <h6>{adminData} </h6>
          </div>
          <CHeaderNav className="ms-3">
            <CDropdown variant="nav-item">
              <CDropdownToggle
                placement="bottom-end"
                className="py-0"
                caret={false}
              >
                <CAvatar src={Logo} size="md" />
              </CDropdownToggle>
              <CDropdownMenu className="pt-0" placement="bottom-end">
                <CDropdownHeader className="bg-light fw-semibold py-2">
                  Account
                </CDropdownHeader>
                <CDropdownItem onClick={adminLogout} className="cursor-pointer">
                  <CIcon icon={cilAccountLogout} className="me-2" />
                  Logout
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CHeaderNav>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
