import { Link, useNavigate } from "react-router-dom";
import LoginImage from "../assets/images/login-image.jpg";

import { toast } from "react-toastify";
import { API_POST_VERIFY_EMAIL_OTP } from "../utils/APIurls";
import { POST } from "../utils/webService";
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import companyLogo from "../assets/images/company-logo.svg";

const Signup = () => {
  const [userInput, setuserInput] = useState({
    fname: "",
    lname: "",
    mobileNo: "",
  });
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const signUp = (e) => {
    setuserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  //   let regEmail =
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // verify Email for Otp
  const VerifyMobile = (e) => {
    e.preventDefault();

    setLoading(true);
    if (!userInput.mobileNo) {
      toast.error("Invalid Mobile");
    } else {
      POST(API_POST_VERIFY_EMAIL_OTP, {
        mobileNo: userInput.mobileNo,
        fname: userInput.fname,
        lname: userInput.lname,
      })
        .then((response) => {
          if (response.status == "200") {
            toast.success(response?.data?.msg);
            if (response?.data?.msg === "You already signed up") {
              navigate(`/user/signup/user-data/${response?.data?.otpId}`);
            } else {
              navigate(`/user/signup/verify-otp/${response?.data?.otpId}`);
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data?.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className=" relative">
      <section className="bg-gray-50">
        <div className=" flex  items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="hidden md:block">
            <img src={LoginImage} alt="login" />
          </div>
          <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
            <div className="p-6 space-y-4  md:space-y-6 sm:p-8  ">
              <div className="flex flex-wrap justify-center">
                <img
                  src={companyLogo}
                  alt="company-logo"
                  height={70}
                  width={70}
                />
              </div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Create Your account
              </h1>
              <form onSubmit={VerifyMobile} className="space-y-2">
                <div className="flex justify-between gap-2">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      First Name
                    </label>
                    <input
                      onChange={signUp}
                      type="text"
                      name="fname"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="first Name "
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Last name
                    </label>
                    <input
                      onChange={signUp}
                      type="text"
                      name="lname"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="last Name"
                      required
                    />
                  </div>
                </div>

                {/* <div>
                      <label  className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                      <input onChange={signUp} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@company.com" required />
                  </div> */}
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Phone Number
                  </label>
                  <input
                    onChange={signUp}
                    type="text"
                    pattern="^\d{10}$"
                    name="mobileNo"
                    maxLength={10}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Enter Phone Number"
                    required
                  />
                </div>

                <div className="block mb-2">
                  <button
                    type="submit"
                    className="w-full cursor-pointer text-white bg-[#a065c7] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Create Account
                  </button>
                  <p className="text-sm font-light text-gray-500 ">
                    Already have an account?{" "}
                    <Link
                      to={"/user/login"}
                      className="font-medium  hover:underline text-blue-500"
                    >
                      Login here
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {loading && (
        <div className=" overflow-hidden border-2  absolute w-screen h-screen bg-gray-400 opacity-75 flex justify-center items-center top-1 z-10">
          <BeatLoader size={15} />
        </div>
      )}
    </div>
  );
};

export default Signup;
