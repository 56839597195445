import { useState } from "react";
import { State, City } from "country-state-city";
import { API_POST_CREATE_OFFERS } from "../../utils/APIurls";
import { POST } from "../../utils/webService";
import { toast } from "react-toastify";
import { inputData } from "../../utils/inputData";

const AddOffer = () => {
  const [stateName, setStateName] = useState("");
  const [requestProd, setRequestProd] = useState("");
  const [requestInput, setRequestInput] = useState("");

  const [addOfferInput, setAddOfferInput] = useState({
    companyName: "",
    panNumber: "",
    gstNumber: "",
    contactNumber: "",
    email: "",
    amount: "",
    city: "",
    address: "",
    credit_retting: "",
    turn_over: "",
  });

  const [cityCode, setCityCode] = useState("");

  const sateInput = (e) => {
    setStateName(e.target.value);
    const filterVlue = e.target.value;
    const code = filterVlue.split(",")[1];
    setCityCode(code);
  };

  const requestProduct = (e) => {
    setRequestProd(e.target.value);
  };

  const addHandler = (e) => {
    setAddOfferInput({ ...addOfferInput, [e.target.name]: e.target.value });
  };

  const countryCode = "IN";
  const stateData = State.getStatesOfCountry(countryCode).map((state) => ({
    value: state.name,
    displayValue: state.name,
    cityCode: state.isoCode,
  }));
  const cityData = City.getCitiesOfState(countryCode, cityCode).map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));

  const phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  const addOfferSubmit = (e) => {
    e.preventDefault();
    if (addOfferInput.panNumber.length < 10) {
      toast.error("Pan Number must be 10 digits");
    } else {
      POST(API_POST_CREATE_OFFERS, {
        companyName: addOfferInput.companyName,
        panNumber: addOfferInput.panNumber,
        gstNumber: addOfferInput.gstNumber,
        contactNumber: addOfferInput.contactNumber,
        email: addOfferInput.email,
        productRequested: requestProd == "others" ? requestInput : requestProd,
        amount: addOfferInput.amount,
        city: addOfferInput.city,
        address: addOfferInput.address,
        state: stateName?.split(",")[0],
        credit_retting: addOfferInput.credit_retting,
        turn_over: addOfferInput.turn_over,
      })
        .then(async ({ data }) => {
          if (data) {
            toast.success("Proposal added successfully !");
            document.getElementById("addOffer").reset();
          }
        })
        .catch((err) => {
          toast.error(err.response?.data?.msg);
        });
    }
  };

  return (
    <div className="flex flex-col justify-center mb-32 overflow-y-auto w-full text-xs  rounded-md p-2 ">
      <h1 className="text-sm pl-3  text-white bg-[#243665] font-bold leading-tight tracking-tight py-3 rounded-md">
        Create Proposal!
      </h1>
      <form
        id="addOffer"
        onSubmit={addOfferSubmit}
        className=" flex  flex-wrap justify-center md:justify-start gap-4 items-center mt-4 px-2 md:px-0"
      >
        <div className="w-full md:w-1/2 lg:w-1/3  px-4 md:px-0">
          <div className=" md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 ">
                Company Name
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                pattern="[a-zA-Z\s]+"
                type="text"
                name="companyName"
                required
              />
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                PAN Number
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                maxLength={10}
                pattern="[a-zA-Z0-9]*"
                type="text"
                name="panNumber"
                required
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                GST Number
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                maxLength={15}
                pattern="[a-zA-Z0-9]*"
                type="text"
                name="gstNumber"
              />
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="  md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Contact Number
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                maxLength={10}
                pattern="^\d{10}$"
                type="text"
                name="contactNumber"
                required
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Email
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="email"
                name="email"
                required
              />
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Product Requested
              </label>
            </div>
            <div className="md:w-2/3">
              <select
                onChange={requestProduct}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                type="text"
              >
                {inputData?.map((s, i) => {
                  return (
                    <option key={i} value={s.value}>
                      {s.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {requestProd == "others" && (
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  other
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  onChange={(e) => setRequestInput(e.target.value)}
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full p-2.5 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  pattern="[a-zA-Z\s]+"
                  type="text"
                  name="other"
                  required
                />
              </div>
            </div>
          )}

          <div></div>
        </div>

        <div className=" px-4 md:px-0 ">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Amount
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full p-2.5 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                pattern="[0-9]+"
                type="text"
                name="amount"
                required
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                State
              </label>
            </div>
            <div className="md:w-2/3">
              <select
                onChange={sateInput}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                type="text"
              >
                <option selected value="">
                  {" "}
                  Select your state
                </option>
                {stateData?.map((s, i) => {
                  return (
                    <option key={i} value={[s.value, s.cityCode]}>
                      {s.displayValue}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                City
              </label>
            </div>
            <div className="md:w-2/3">
              <select
                onChange={addHandler}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                type="text"
                name="city"
              >
                <option selected value="">
                  Select your city
                </option>
                {cityData?.map((s, i) => {
                  return (
                    <option key={i} value={s.value}>
                      {s.displayValue}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Address
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                pattern="[a-zA-Z0-9\s]+"
                type="text"
                name="address"
                required
              />
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Turn Over In Last financial Year (CRs)
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full p-2.5 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
                pattern="[0-9\.]+"
                step="0.001"
                name="turn_over"
                required
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                Credit Rating(If Any)
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                onChange={addHandler}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full p-2.5 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                maxLength={10}
                type="text"
                name="credit_retting"
                required
              />
            </div>
          </div>

          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3">
              <button
                className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold w-full p-2.5 rounded"
                type="submit"
              >
                Create Proposal
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddOffer;
