import AddOffer from "../formTab/AddOffer";
const OfferDashboard = () => {
  //   const [shaveAllOffer, setShaveAllOffer] = useState()
  // const code = sessionStorage.getItem("code")
  //    useEffect(()=>{

  //     GET(API_GET_ONE_USER_ALL_OFFERS(code))
  //     .then(({data})=>{
  //       if(data){
  //         setShaveAllOffer(data?.allOffers)
  //       }
  //     })
  //    },[])

  return (
    <div>
      <AddOffer />
    </div>
  );
};

export default OfferDashboard;
