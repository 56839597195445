import axios from 'axios';
const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL ||'https://partner-apidev.capitalsetu.com'

// const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL ||'http://localhost:9012'

axios.defaults.baseURL = BASE_URL;
const createHeader = (_URL, options = {}) => {
  let header = {
    Accept: '/',
    Authorization: 'Bearer ' + sessionStorage.getItem('token')
  };
  options = { ...options, headers: header };
  return { URL: _URL, options: options };
};

const POST = (_URL, data = {}, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.post(URL, data, options);
};

const GET = (_URL, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.get(URL, options);
};

const PATCH = (_URL, data, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.patch(URL, data, options);
};
const DELETE = (_URL, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.delete(URL, options);
};

export { POST, GET, PATCH, DELETE };
