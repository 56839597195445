import { useState } from "react"

 

const UserProfile = () => {
    const [bankinput, setBankInput] = useState({
       bankholderName:"",
       bankName:"",
       bankAccountNumber:"",
       ifcsCode:""
    })

    const bankHandler =(e)=>{
        setBankInput({...bankinput,[e.target.name]:e.target.value})
    }

    const bankSubmit =(e)=>{
        e.preventDefault()
        console.log('bankDetail',bankinput)
    }
  return (
<div className="flex flex-col justify-center py-10 px-4 ">
<h1 className="text-xl ml-32 font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
             Add Your Bank Detail !
 </h1>
<form onSubmit={bankSubmit} className="w-full max-w-sm mt-4">

<div className="md:flex md:items-center mb-6">
  <div className="md:w-1/3">
    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
    Account Holder Name
    </label>
  </div>
  <div className="md:w-2/3">
    <input onChange={bankHandler} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" name="bankholderName"/>
  </div>
</div>
<div className="md:flex md:items-center mb-6">
  <div className="md:w-1/3">
    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
    Bank Account Name
    </label>
  </div>
  <div className="md:w-2/3">
    <input onChange={bankHandler}  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" name="bankName"  />
  </div>
</div>
<div className="md:flex md:items-center mb-6">
  <div className="md:w-1/3">
    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
   Account Number
    </label>
  </div>
  <div className="md:w-2/3">
    <input onChange={bankHandler}  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="number" name="bankAccountNumber"  />
  </div>
</div>

<div className="md:flex md:items-center mb-6">
  <div className="md:w-1/3">
    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" >
   IFSC code
    </label>
  </div>
  <div className="md:w-2/3">
    <input onChange={bankHandler}  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" name="ifcsCode"  />
  </div>
</div>

<div className="md:flex md:items-center">
  <div className="md:w-1/3"></div>
  <div className="md:w-2/3">
    <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
    Add bank
    </button>
  </div>
</div>
</form>
</div>
  )
}

export default UserProfile