

import { AiFillFileAdd, AiOutlineDashboard} from "react-icons/ai";
import { AiOutlineDisconnect} from "react-icons/ai";
import Menu from "../navigationBar/Menu";

const AdminSideBar = () => {
  return (
    <div className="overflow-y-scroll h-screen  ">
    <div className=" flex flex-col">
          
      <div className="flex flex-col">
      <Menu url={'/admin'} icon={<AiOutlineDashboard/>} title={'Dashboard'}/>
      <Menu url={`/Allconnecters`} icon={<AiOutlineDisconnect/>} title={'All Connectors'}/>
      <Menu url={'/list-Of-All-proposal'} icon={<AiFillFileAdd/>} title={'List Of All Proposal'}/> 
    
  
     </div>
     </div>
  </div>
  )
}

export default AdminSideBar