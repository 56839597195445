import React, { useEffect, useState } from "react";

import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
  CCard,
  CCardBody,
  CButton,
  CButtonGroup,
} from "@coreui/react";
import { getStyle, hexToRgba } from "@coreui/utils";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import CIcon from "@coreui/icons-react";
import { cilArrowTop, cilCloudDownload, cilOptions } from "@coreui/icons";
import { GET } from "../../utils/webService";
import {
  API_GET_GRAPH_DATA,
  API_GET_GRAPH_DATA_LINEAR,
} from "../../utils/APIurls";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const HomeDashboard = () => {
  const [graphData, setGraphData] = useState([]);
  // const [linearData, setlinearData] = useState([]);
  // const [monthValue, setMonthValue] = useState([]);
  const [pending, setPending] = useState([]);
  const [apprved, setapprved] = useState([]);
  const [reject, setreject] = useState([]);

  const random = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const code = sessionStorage.getItem("code");

  useEffect(() => {
    GET(API_GET_GRAPH_DATA(code))
      .then(({ data }) => {
        setGraphData(data?.graphData);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    GET(API_GET_GRAPH_DATA_LINEAR(code))
      .then(({ data }) => {
        let pending = data?.totalOffers?.filter((re) => {
          if (re.status.includes("pending")) {
            return re;
          }
        });
        let approved = data?.totalOffers?.filter((re) => {
          if (re.status.includes("approved")) {
            return re;
          }
        });
        let rejected = data?.totalOffers?.filter((re) => {
          if (re.status.includes("reject")) {
            return re;
          }
        });
        // let offersData = data.totalOffers?.map((res)=>monthNames[new Date(res.createdAt).getMonth()])
        let approvedData = pending?.map(
          (res) => monthNames[new Date(res.createdAt).getMonth()]
        );

        let pendindgData = approved?.map(
          (res) => monthNames[new Date(res.createdAt).getMonth()]
        );

        let rejectData = rejected?.map(
          (res) => monthNames[new Date(res.createdAt).getMonth()]
        );

        // getMonthFilter(offersData)
        setPending(getMonthFilter(approvedData));
        setapprved(getMonthFilter(pendindgData));
        setreject(getMonthFilter(rejectData));
        // setlinearData(data.totalOffers)
      })
      .catch((err) => err);
  }, []);
  function getMonthFilter(result) {
    return monthNames.map((re) => {
      let data = getMonthdata(re, result);
      return data.length;
    });
    // setMonthValue()
  }
  function getMonthdata(month, result) {
    return result.filter((re) => {
      if (re.includes(month)) {
        return re;
      }
    });
  }

  return (
    <div>
      <CRow>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="primary"
            value={
              <>
                {graphData?.TotalOffers}
                <span className="fs-6 fw-normal">
                  <CIcon icon={cilArrowTop} />
                </span>
              </>
            }
            title="Total Proposal"
            action={
              <CDropdown alinment="end">
                <CDropdownToggle
                  color="transparent"
                  caret={false}
                  className="p-0"
                >
                  <CIcon
                    icon={cilOptions}
                    className="text-high-emphasis-inverse"
                  />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            }
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: "70px" }}
                data={{
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "november",
                    "December",
                  ],

                  datasets: [
                    {
                      label: "My First dataset",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      pointBackgroundColor: getStyle("--cui-primary"),
                      data: [
                        65, 59, 84, 84, 51, 55, 40, 65, 59, 84, 84, 51, 55, 40,
                      ],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                        drawBorder: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    y: {
                      min: 30,
                      max: 89,
                      display: false,
                      grid: {
                        display: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 1,
                      tension: 0.4,
                    },
                    point: {
                      radius: 4,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="info"
            value={
              <>
                {graphData?.totalApproved}
                <span className="fs-6 fw-normal">
                  (
                  {graphData?.TotalOffers > 0
                    ? (
                        (graphData?.totalApproved / graphData?.TotalOffers) *
                        100
                      ).toFixed(2)
                    : 0}
                  % <CIcon icon={cilArrowTop} />)
                </span>
              </>
            }
            title="Approved"
            action={
              <CDropdown alignment="end">
                <CDropdownToggle
                  color="transparent"
                  caret={false}
                  className="p-0"
                >
                  <CIcon
                    icon={cilOptions}
                    className="text-high-emphasis-inverse"
                  />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            }
            chart={
              <CChartLine
                className="mt-3 mx-3"
                style={{ height: "70px" }}
                data={{
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "november",
                    "December",
                  ],

                  datasets: [
                    {
                      label: "My First dataset",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      pointBackgroundColor: getStyle("--cui-info"),
                      data: apprved,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      grid: {
                        display: false,
                        drawBorder: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    y: {
                      min: -9,
                      max: 39,
                      display: false,
                      grid: {
                        display: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 1,
                    },
                    point: {
                      radius: 4,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="warning"
            value={
              <>
                {graphData?.TotalPending}
                <span className="fs-6 fw-normal">
                  (
                  {graphData?.TotalOffers > 0
                    ? (
                        (graphData?.TotalPending / graphData?.TotalOffers) *
                        100
                      ).toFixed(2)
                    : 0}
                  % <CIcon icon={cilArrowTop} />)
                </span>
              </>
            }
            title="Pending"
            action={
              <CDropdown alignment="end">
                <CDropdownToggle
                  color="transparent"
                  caret={false}
                  className="p-0"
                >
                  <CIcon
                    icon={cilOptions}
                    className="text-high-emphasis-inverse"
                  />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            }
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "november",
                    "December",
                  ],

                  datasets: [
                    {
                      label: "My First dataset",
                      backgroundColor: "rgba(255,255,255,.2)",
                      borderColor: "rgba(255,255,255,.55)",
                      data: pending,
                      fill: true,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4"
            color="danger"
            value={
              <>
                {graphData?.totalReject}
                <span className="fs-6 fw-normal">
                  (
                  {graphData?.TotalOffers > 0
                    ? (
                        (graphData?.totalReject / graphData?.TotalOffers) *
                        100
                      ).toFixed(2)
                    : 0}
                  % <CIcon icon={cilArrowTop} />)
                </span>
              </>
            }
            title="Reject"
            action={
              <CDropdown alignment="end">
                <CDropdownToggle
                  color="transparent"
                  caret={false}
                  className="p-0"
                >
                  <CIcon
                    icon={cilOptions}
                    className="text-high-emphasis-inverse"
                  />
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem>Action</CDropdownItem>
                  <CDropdownItem>Another action</CDropdownItem>
                  <CDropdownItem>Something else here...</CDropdownItem>
                  <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            }
            chart={
              <CChartBar
                className="mt-3 mx-3"
                style={{ height: "70px" }}
                data={{
                  labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "november",
                    "December",
                  ],

                  datasets: [
                    {
                      label: "My First dataset",
                      backgroundColor: "rgba(255,255,255,.2)",
                      borderColor: "rgba(255,255,255,.55)",
                      data: reject,
                      barPercentage: 0.6,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                        drawTicks: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    y: {
                      grid: {
                        display: false,
                        drawBorder: false,
                        drawTicks: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                  },
                }}
              />
            }
          />
        </CCol>
      </CRow>

      <CCard className="pb-24 mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="Proposal" className="card-title mb-0">
                Proposal View
              </h4>
              <div className="small text-medium-emphasis">
                January - December {new Date().getFullYear()}
              </div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton color="primary" className="float-end">
                <CIcon icon={cilCloudDownload} />
              </CButton>
              <CButtonGroup className="float-end me-3">
                {["Month"].map((value) => (
                  <CButton
                    color="outline-secondary"
                    key={value}
                    className="mx-0"
                    active={value === "Month"}
                  >
                    {value}
                  </CButton>
                ))}
              </CButtonGroup>
            </CCol>
          </CRow>
          <CChartLine
            style={{ height: "300px", marginTop: "40px" }}
            data={{
              labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "november",
                "December",
              ],
              datasets: [
                {
                  label: "Approved",
                  backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
                  borderColor: getStyle("--cui-info"),
                  pointHoverBackgroundColor: getStyle("--cui-info"),
                  borderWidth: 2,
                  data: apprved,
                  fill: true,
                },
                {
                  label: "Pending",
                  backgroundColor: "transparent",
                  borderColor: "rgb(249,177,21)",
                  pointHoverBackgroundColor: getStyle("--cui-success"),
                  borderWidth: 2,
                  data: pending,
                  fill: true,
                },
                {
                  label: "Reject",
                  backgroundColor: "transparent",
                  borderColor: getStyle("--cui-danger"),
                  pointHoverBackgroundColor: getStyle("--cui-danger"),
                  borderWidth: 1,
                  borderDash: [8, 5],
                  data: reject,
                  fill: true,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 10,
                    stepSize: Math.ceil(250 / 10),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
      </CCard>
    </div>
  );
};

export default HomeDashboard;
