
import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react'
import  CompanyLogo from '../../assets/images/company-logo.svg'
import AdminSideBar from './AdminSideBar'
import CIcon from '@coreui/icons-react'

const AppSidebar = () => {
  return (
    
    <CSidebar
      position="fixed"
     className='!bg-[#243665]'
    >
      <CSidebarBrand className=' !bg-white gap-2 items-center  border border-gray-200'  >
    
      <img src={CompanyLogo} alt="" width={60} height={60} />

        <h4 className='text-teal-400 pt-4'>ADMIN</h4>
      </CSidebarBrand>
      <CSidebarNav >
      
         <AdminSideBar/>
      </CSidebarNav>
    
    </CSidebar>
  )
}

export default AppSidebar