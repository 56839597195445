import { useNavigate } from "react-router-dom"


const Notfound = () => {
    const navigate= useNavigate()
  return (
    <section class="bg-white dark:bg-gray-900 flex flex-col justify-center items-center  ">
   
              <div>
            <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Page not found</h1>
            </div>

            <div>
            <p class="mt-4 text-gray-500 dark:text-gray-400">Sorry, the page you are looking for doesn't exist.</p>
            </div>

            <div class="flex items-center mt-6 gap-x-3">
           

                <button onClick={()=>navigate('/user/login')} class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                    Take me home
                </button>
            </div>

         
       
    
</section>
  )
}

export default Notfound