import { useEffect, useState } from "react";
import {
  API_GET_ONE_USER_ALL_OFFERS,
  API_PATCH_UPDATE_OFFER_STATUS,
} from "../../utils/APIurls";
import { GET, PATCH } from "../../utils/webService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle, CHeaderNav } from "@coreui/react";

const TdStyle = {
  ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-2 px-3 text-sm font-semibold text-white lg:py-7 lg:px-4`,
  TdStyle: `border-b  border-[#E8E8E8] bg-[#F3F6FF] py-2 px-2 text-center text-xs  font-normal text-dark`,
  TdButton: ` cusor inline-block px-6 py-2 border rounded border-primary text-green-500 `,
  TdButton2: ` cursor-pointer  inline-block px-6 py-2 border rounded border-primary text-yellow-500 `,
  TdButton3: `  inline-block px-6 py-2 border rounded border-primary text-red-500 `,

};
const LIstOfOneUserOfferDashboard = () => {
  const [shaveAllOffer, setShaveAllOffer] = useState([]);
  const [showUpdate, setShowUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { id } = useParams();
const limit =8
  const updateUserStatus = (offerId,status) => {
    PATCH(API_PATCH_UPDATE_OFFER_STATUS(offerId),{status:status})
      .then(({ data }) => {
        if (data) {
          toast.success("status has been updated");
          setShowUpdate((prev) => !prev);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.msg);
      });
  };

  const code = id;
  useEffect(() => {
    GET(API_GET_ONE_USER_ALL_OFFERS(code,page,limit)).then(({ data }) => {
      if (data) {
        setShaveAllOffer(data?.allOffers);
        setCount(data.count);
      }
    });
  }, [showUpdate,page]);
  return (
    <>

     <section className={`bg-[#F3F6FF] max-h-full  overflow-x-auto rounded-md ${shaveAllOffer.length <8 && 'pb-16 ' }`} >
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full ">
            <div className="max-w-full ">
              <table className="w-full table-auto">
                <thead className="text-center bg-[#243665]">
                  <tr>
                    <th className={TdStyle.ThStyle}> Code</th>
                    <th className={TdStyle.ThStyle}> Date</th>

                    <th className={TdStyle.ThStyle}> Comapny </th>
                    <th className={TdStyle.ThStyle}> Email </th>
                    <th className={TdStyle.ThStyle}> PAN </th>
                      <th className={TdStyle.ThStyle}>GST </th>
                      <th className={TdStyle.ThStyle}>Contact</th>
                      <th className={TdStyle.ThStyle}>Amount</th>
                      <th className={`w-1/6 min-w-[200px] border-l border-transparent py-2 px-3 text-sm font-semibold text-white lg:py-7 lg:px-4`}>Product Requested</th>
                      <th className={TdStyle.ThStyle}>Credit Rating</th>
                      <th className={TdStyle.ThStyle}>Turn Over</th>
                      <th className={TdStyle.ThStyle}>State</th>
                      <th className={TdStyle.ThStyle}>City</th>
                      <th className={TdStyle.ThStyle}>Address</th>
                      <th className={TdStyle.ThStyle}>Status</th>
                  </tr>
                </thead>

                {shaveAllOffer.length !==0?
                
                  shaveAllOffer.map((el, index) => {
                    return (
                      <tbody>
                        <tr key={index}>
                          <td className={TdStyle.TdStyle}>{code}</td>
                          <td className={TdStyle.TdStyle}>{el?.createdAt.split('T')[0]}</td>

                          <td className={TdStyle.TdStyle}>{el.companyName}</td>
                          <td className={TdStyle.TdStyle}>{el.email}</td>
                          <td className={TdStyle.TdStyle}>{el.panNumber}</td>
                          <td className={TdStyle.TdStyle}>{el.gstNumber}</td>
                          <td className={TdStyle.TdStyle}>
                            {el.contactNumber}
                          </td>
                          <td className={TdStyle.TdStyle}>{el.amount}</td>
                          <td className={TdStyle.TdStyle}>{el.productRequested}</td>
                            <td className={TdStyle.TdStyle}>{el.credit_retting}</td>
                            <td className={TdStyle.TdStyle}>{el.turn_over}</td>
                          <td className={TdStyle.TdStyle}>{el.state}</td>
                          <td className={TdStyle.TdStyle}>{el.city}</td>
                          <td className={TdStyle.TdStyle}>{el.address}</td>

                          <td className={TdStyle.TdStyle}>
                           {el?.status === "pending" ? (
                              <>
            
                                <CHeaderNav className="ms-3">
                                  <CDropdown variant="nav-item">
                                    <CDropdownToggle
                                      placement="bottom-end"
                                      className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-yellow-500`}
                                      caret={false}
                                    >
                                     pending
                                    </CDropdownToggle>
                                    <CDropdownMenu
                                      className="pt-0 "
                                      placement="bottom-end"
                                    >
                                      <CDropdownHeader className="bg-light fw-semibold py-2">
                                       status
                                      </CDropdownHeader>
                                      <CDropdownItem
                                        onClick={()=>updateUserStatus(el?._id,"approved")}
                                        className="cursor-pointer tex-green-500"
                                      >
                                       
                                       Approved
                                      </CDropdownItem>
                                      <CDropdownItem
                                     onClick={()=>updateUserStatus(el?._id,"reject")}
                                        className="cursor-pointer text-red-500"
                                      >
                                       
                                       Reject
                                      </CDropdownItem>
                                    </CDropdownMenu>
                                  </CDropdown>
                                </CHeaderNav>
                              </>
                            ) : el.status ==='approved'?
                            (
                               
                              // <div className={TdStyle.TdButton}>{el.status}</div>
                              <CHeaderNav className="ms-3">
                              <CDropdown variant="nav-item">
                                <CDropdownToggle
                                  placement="bottom-end"
                                  className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-green-500`}

                                  caret={false}
                                >
                                approved
                                </CDropdownToggle>
                                <CDropdownMenu
                                  className="pt-0"
                                  placement="bottom-end"
                                >
                                  <CDropdownHeader className="bg-light fw-semibold py-2">
                                   status
                                  </CDropdownHeader>
                                  
                                  <CDropdownItem
                                 onClick={()=>updateUserStatus(el?._id,"reject")}
                                    className="cursor-pointer text-red-500"
                                  >
                                   
                                   Reject
                                  </CDropdownItem>
                                </CDropdownMenu>
                              </CDropdown>
                            </CHeaderNav>
                                
                            )
                            :
                              // <div className={TdStyle.TdButton3}>{el.status}</div>
                              <CHeaderNav className="ms-3">
                              <CDropdown variant="nav-item">
                                <CDropdownToggle
                                  placement="bottom-end"
                                  className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-red-500`}
                                  caret={false}
                                >
                                Reject
                                </CDropdownToggle>
                                <CDropdownMenu
                                  className="pt-0"
                                  placement="bottom-end"
                                >
                                  <CDropdownHeader className="bg-light fw-semibold py-2">
                                   status
                                  </CDropdownHeader>
                                  
                                  <CDropdownItem
                                 onClick={()=>updateUserStatus(el?._id,"approved")}
                                    className="cursor-pointer text-red-500"
                                  >
                                   
                                Approved
                                  </CDropdownItem>
                                </CDropdownMenu>
                              </CDropdown>
                            </CHeaderNav>
                        }

                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                  :
                  <tbody className="">
                  <tr>
                    <th className="text-center" > Data not found</th>
                    
                  </tr>
                </tbody>
                  }
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    {shaveAllOffer.length !==0 &&
     <div className="flex justify-center bg-[#F3F6FF] py-2 text-xs">
     <div className="  w-fit justify-center border rounded-l-md rounded-r-md  ">
       <button
       disabled={page ===1}
         className={`px-3 py-2 border-x rounded-l-md ${
           page === 1 ? "bg-gray-400" : "hover:bg-blue-400"
         } `}
         onClick={() => setPage(page - 1)}
       >
         Prev
       </button>
       <button className="px-3 py-2 border-x ">
         {page}{` of  ${(Math.ceil(count / limit))}`}
       </button>
       <button
         disabled={page === Math.ceil(count / limit)}
         className={`px-3 py-2 border-x rounded-r-md ${
           page === Math.ceil(count / limit)
             ? "bg-gray-400 "
             : "hover:bg-blue-400"
         }`}
         onClick={() => setPage(page + 1)}
       >
         Next
       </button>
     </div>
   </div>
}
    </>
  

  );
};

export default LIstOfOneUserOfferDashboard;
