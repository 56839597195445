
import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  // useNavigate,
} from "react-router-dom";
import Login from './pages/Login';
import Signup from './pages/Signup';

import Notfound from './pages/Notfound';
import { ToastContainer } from 'react-toastify';
import OtpVerify from './pages/OtpVerify';
import Home from './pages/userDashboard/Home';
import Profile from './pages/userDashboard/Profile';
import AddOffer from './pages/userDashboard/AddOffer';
import Reports from './pages/userDashboard/Reports';
import Main from './pages/main/Main';
import AllConnectors from './pages/adminDashboard/AllConnectors';
import ListOfAllOffers from './pages/adminDashboard/ListOfAllOffers';
import ListOfOneUserOffers from './pages/adminDashboard/ListOfOneUserOffers';
import UserData from './pages/UserData';
import ProtectedRoutes from './components/ProtectedRoutes';
// import AdminProtectedRoutes from './components/AdminProtectedRoutes';
import UserProfile from './components/formTab/UserProfile';
import Admin from './pages/adminDashboard/Admin';
import ForgotPass from './pages/ForgotPass';
function App() {
const role = sessionStorage.getItem('role');

  const router = createBrowserRouter(
  createRoutesFromElements(

       <>
      <Route>
      <Route path="user/login" element={<Login />}/>  
      <Route path="/user/signup" element={ <Signup/>}/>  
      <Route path="/user/signup/verify-otp/:id" element={<OtpVerify/>}/>  
      <Route path="/user/signup/user-data/:id" element={ <UserData/>}/>
      <Route path="/user/forgot-password" element={ <ForgotPass/>}/>

       
      </Route>
       

    {role?.includes('user') ? 
      <Route>
      <Route path="/" element={<ProtectedRoutes>  <Home/></ProtectedRoutes> }/>  
      <Route path="/profile" element={<ProtectedRoutes>  <Profile/></ProtectedRoutes>}/>  
      <Route path="/add-offer" element={<ProtectedRoutes> <AddOffer/>  </ProtectedRoutes>}/>  
      <Route path="/reports" element={<ProtectedRoutes> <Reports/></ProtectedRoutes> }/> 
      <Route path="/create-offer" element={<ProtectedRoutes><Main/></ProtectedRoutes> }/> 
      <Route path="/edit-profile" element={<ProtectedRoutes><UserProfile/></ProtectedRoutes> }/> 
      <Route path="*" element={<ProtectedRoutes>  <Notfound/></ProtectedRoutes> }/> 
         
      </Route>
      :
      <Route>
      <Route path="/Allconnecters" element={<ProtectedRoutes>  <AllConnectors/> </ProtectedRoutes>}/> 
      <Route path="/list-Of-All-proposal" element={<ProtectedRoutes>  <ListOfAllOffers/> </ProtectedRoutes>  }/> 
      <Route path="/list-one-user-offer/:id" element={<ProtectedRoutes> <ListOfOneUserOffers/> </ProtectedRoutes>}/>
      <Route path="/admin" element={<ProtectedRoutes><Admin/> </ProtectedRoutes>}/>
      <Route path="*" element={<ProtectedRoutes> <Notfound/></ProtectedRoutes>}/> 
      </Route>

    }
      
      </>
  )
);
  return (
    <div >
     <ToastContainer/>
    <RouterProvider router={router} />

    </div>
  );
}

export default App;
