
import { useState } from "react"
import AllConnectorsDashboard from "../../components/admin/AllConnectorsDashboard"
import AppSidebar from "../../components/admin/AppSidebar"
import AppHeader from "../../components/admin/AppHeader"


const AllConnectors = () => {

   let [openMenu, setOpenMenu] = useState(false)
  


  return (
   <div className="w-screen flex  ">
        <div className={`${openMenu?'hidden':'flex w-[20%] '}`}>
        <AppSidebar/>
           
        </div>
        <div className={`flex flex-col ${openMenu? 'w-full':' w-[80%]'} max-h-screen overflow-hidden `}>
      <AppHeader setOpenMenu={setOpenMenu}/>
      <div className="p-2">
      <AllConnectorsDashboard/>

      </div>
  
    </div>
  </div>
  )
}

export default AllConnectors