import { useState } from "react"
import AppSidebar from "../../components/admin/AppSidebar"
import ListOfAllOffersDashboard from "../../components/admin/ListOfAllOffersDashboard"
import AppHeader from "../../components/admin/AppHeader"


const ListOfAllOffers = () => {
  let [openMenu, setOpenMenu] = useState(false)
  return (
    <div className="w-screen flex">
    <div className={`${openMenu?'hidden':'flex w-[20%] '} `}>
    <AppSidebar/>
       
    </div>
    <div className={`flex flex-col ${openMenu? 'w-full':' w-[80%]'} max-h-screen overflow-hidden`}>
  <AppHeader setOpenMenu={setOpenMenu}/>
  <div className="p-2 overflow-y-auto">
  <ListOfAllOffersDashboard/>
  </div>

</div>
</div>
  )
}

export default ListOfAllOffers