



const API_POST_VERIFY_EMAIL_OTP = '/api/v1/send-otp'
const API_PATCH_RESEND_OTP = '/api/v1/resend-otp'
const SEND_OTP_MOBILE ='/api/v1/send-opt-to-mobile'
const APT_POST_VERIFY_OTP ='/api/v1/verify-otp'
const API_CHANGE_PASSWPORD ='/api/v1/change-password' 
const API_POST_SUBMIT_USER_DETAILS = '/api/v1/user-details'
const API_GET_USER_EMAIL=(otpId)=> `/api/v1/user-email/${otpId}`
const APT_POST_EMAIL_SIGN_IN ='/api/v1/user-signin'
const API_POST_CREATE_OFFERS = '/api/v1/add-offers'
const API_GET_ONE_USER_ALL_OFFERS =(code,page,limit)=> `/api/v1/one-user-all-offers/${code}/?page=${page}&limit=${limit}`
const API_GET_ALL_CONNECTORS =(code,role,page,limit)=> `/api/v1/all-connectors/${code}/${role}/?page=${page}&limit=${limit}`
const API_PATCH_USER_APPROVED =(userId,role)=> `/api/v1/get-approved/${userId}/${role}`
const API_GET_ALL_APPROVED_CONNECTORS =(code,role,page,limit)=> `/api/v1/get-all-approved-connectors/${code}/${role}/?page=${page}&limit=${limit}`
const API_PATCH_UPDATE_OFFER_STATUS = (offerId)=>`/api/v1/update-user-status/${offerId}`
const API_GET_SINGLE_DATA = (code)=> `/api/v1/get-one-user-data/${code}`
const API_GET_GRAPH_DATA = (code)=>`/api/v1/get-graph-data/${code}`
const API_GET_GRAPH_DATA_LINEAR = (code)=>`/api/v1/get-graph-data-for-linear/${code}`
const API_GET_GRAPH_DATA_ADMIN = (code)=>`/api/v1/get-graph-data-by-admin/${code}`
const API_GET_GRAPH_DATA_LINEAR_ADMIN = (code)=>`/api/v1/get-graph-data-for-linear-by-admin/${code}`






export{
    API_POST_VERIFY_EMAIL_OTP,
    APT_POST_VERIFY_OTP,
    API_PATCH_RESEND_OTP,
    API_POST_SUBMIT_USER_DETAILS,
    API_GET_USER_EMAIL,
    SEND_OTP_MOBILE,
    APT_POST_EMAIL_SIGN_IN,
    API_POST_CREATE_OFFERS,
    API_GET_ONE_USER_ALL_OFFERS,
    API_GET_ALL_CONNECTORS,
    API_PATCH_USER_APPROVED,
    API_GET_ALL_APPROVED_CONNECTORS,
    API_PATCH_UPDATE_OFFER_STATUS,
    API_GET_SINGLE_DATA,
    API_GET_GRAPH_DATA,
    API_GET_GRAPH_DATA_LINEAR,
    API_GET_GRAPH_DATA_LINEAR_ADMIN,
    API_GET_GRAPH_DATA_ADMIN,
    API_CHANGE_PASSWPORD
    
}