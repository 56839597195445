
import { NavLink } from "react-router-dom";

const Menu = ({ title, icon,url }) => {
  return (

    <NavLink to={url} className=' activecheck  '  >
    <div  className={`flex items-center space-x-3 cursor-pointer keyy
    text-xs  font-semibold text-white py-2 px-2 hover:bg-gray-300 
     hover:!text-[#243665] hover:scale-1005 rounded-sm transition duration-150 ease-in-out `}>
        
      <div className="pl-4 text-lg ">{icon}</div>
      <div className="">
        <div className="text-lg ">{title}</div>
      </div>
   
    </div>
    </NavLink>
  
   
  );
};

export default Menu;
