import { Link, useNavigate } from "react-router-dom";
import signupImage from "../assets/images/login-image.jpg";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { POST } from "../utils/webService";
import { APT_POST_EMAIL_SIGN_IN } from "../utils/APIurls";
import BeatLoader from "react-spinners/BeatLoader";
import companyLogo from "../assets/images/company-logo.svg";
const Login = () => {
  const [userInput, setuserInput] = useState({
    username: "",
    password: "",
  });
  const [userData, setData] = useState();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  useEffect(() => {
    let role = sessionStorage.getItem("role");
    if (!!role && role === "admin") {
      navigate("/admin");
    } else if (!!role && role === "user") {
      navigate("/");
    }
  }, [userData]);

  const navigate = useNavigate();
  const signUp = (e) => {
    if (e.target.name == "username") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
    setuserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    setLoading(true);
    POST(APT_POST_EMAIL_SIGN_IN, userInput)
      .then(({ data }) => {
        //  if(data){
        setData(data);
        toast.success("user has successfuly loged In");
        sessionStorage.setItem("token", data.accessToken);
        sessionStorage.setItem("role", data.role);
        sessionStorage.setItem("code", data.code);
        sessionStorage.setItem("name", data.name);
        setLoading(true);
        window.location.reload();

        //  }
      })
      .catch((err) => {
        toast.error(err.response?.data?.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="relative">
        <section className="bg-gray-50">
          <div className="flex  items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="hidden md:block">
              <img src={signupImage} alt="signupImage" />
            </div>
            <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
                <div className="flex flex-wrap justify-center">
                  <img
                    src={companyLogo}
                    alt="company-logo"
                    height={70}
                    width={70}
                  />
                </div>
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                  Login here !!
                </h1>
                <form
                  onSubmit={loginHandler}
                  className="space-y-4 md:space-y-6"
                >
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Username
                    </label>
                    <input
                      onChange={signUp}
                      maxLength={10}
                      type="text"
                      name="username"
                      id="mobileNo"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Enter Phone Number.."
                      required
                    />
                  </div>

                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Password
                    </label>
                    <input
                      onChange={signUp}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Login
                  </button>

                  <p className="text-xs font-light text-center text-gray-500 ">
                    <Link
                      to={"/user/forgot-password"}
                      className="font-medium  hover:underline text-blue-500"
                    >
                      Forgot Password?
                    </Link>
                  </p>
                  <p className="text-sm font-light text-gray-500 ">
                    you don't have An Account?{" "}
                    <Link
                      to={"/user/signup"}
                      className="font-medium  hover:underline text-blue-500"
                    >
                      signup here
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        {loading && (
          <div className=" overflow-hidden border-2  absolute w-screen h-screen bg-gray-400 opacity-75 flex justify-center items-center top-1 z-10">
            <BeatLoader size={15} />
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
