import { useEffect, useState } from "react";
import otpverifyImage from "../assets/images/login-image.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { PATCH, POST } from "../utils/webService";
import { API_PATCH_RESEND_OTP, APT_POST_VERIFY_OTP } from "../utils/APIurls";
import { toast } from "react-toastify";
import companyLogo from "../assets/images/company-logo.svg";
const OtpVerify = () => {
  const [otp, setOtp] = useState({
    otp: "",
  });
  let [counter, setConter] = useState(90);
  const router = useParams();

  const navigate = useNavigate();
  window.onload = function () {
    navigate("/user/signup");
  };
  const otpInput = (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      setConter((counter) => {
        if (counter > 0) {
          return (counter -= 1);
        }
      });
      return () => clearInterval(interval);
    }, 1000);
  }, [router]);
  
  const verifyOtphandler = (e) => {
    e.preventDefault();

    POST(APT_POST_VERIFY_OTP, { otpId: router?.id, otp: otp?.otp })
      .then(({ data }) => {
        if (data) {
          toast.success(data.msg);
          navigate(`/user/signup/user-data/${router?.id}`);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  const resendEmailOTP = (e) => {
    PATCH(API_PATCH_RESEND_OTP, { otpId: router?.id })
      .then(({ data }) => {
        setConter((counter = 90));
        console.log("counter", counter);
        if (data) {
          toast.success(data.msg);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  return (
    <div className="flex  items-center px-8 bg-white py-8 mx-auto ">
      <div className="hidden md:block">
        <img className="" src={otpverifyImage} alt="otpImage" />
      </div>
      <div className="w-full bg-white rounded-lg shadow  ">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
          <div className="flex flex-wrap justify-center">
            <img src={companyLogo} alt="company-logo" height={70} width={70} />
          </div>
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
            Verify OTP
          </h1>
          <form onSubmit={verifyOtphandler} className="space-y-4 md:space-y-6">
            {counter !== undefined && (
              <p className="text-[12px] text-green-500 ">
                One-Time Password (OTP) has sent to your Mobile number{" "}
                <span className="text-sm"> {counter} </span>
              </p>
            )}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                OTP
              </label>
              <input
                onChange={otpInput}
                type="text"
                name="otp"
                maxLength={6}
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="00567"
                required
              />
            </div>

            <button
              type="submit"
              className={`w-full text-white ${
                counter !== undefined ? "bg-blue-600" : "bg-gray-300"
              } hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center `}
              disabled={counter == undefined}
            >
              Verify OTP
            </button>
            <button
              onClick={resendEmailOTP}
              type="button"
              className={`w-full text-white ${
                counter == undefined ? "bg-blue-600" : "bg-gray-300"
              } hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              disabled={counter !== undefined}
            >
              Resend Otp{" "}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
