import { useEffect, useState } from "react";
import { GET, PATCH } from "../../utils/webService";
import {
  API_GET_ALL_CONNECTORS,
  API_PATCH_USER_APPROVED,
} from "../../utils/APIurls";
import { toast } from "react-toastify";
import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeaderNav,
} from "@coreui/react";

const TdStyle = {
  ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-2 px-3 text-sm  font-semibold  text-white lg:py-7 lg:px-4`,
  TdStyle: `border-b  border-[#E8E8E8] bg-[#F3F6FF] py-2 px-2 text-center text-xs  font-normal text-dark`,
  TdButton: ` cusor inline-block px-6 py-2 border rounded border-primary text-green-500 `,
  TdButton2: ` cursor-pointer  inline-block px-6 py-2 border rounded border-primary text-yellow-500 `,
  TdButton3: `  inline-block px-6 py-2 border rounded border-primary text-red-500 `,
};

const AllConnectorsDashboard = () => {
  const [allcConnectors, SetAllconnectors] = useState([]);
  const [chnageStatus, setChangeStatus] = useState(false);
  // api for all connectors

  const role = sessionStorage.getItem("role");
  const code = sessionStorage.getItem("code");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  let limit = 8;
  // useEffect(() => {
  //   GET(API_GET_ONE_USER_ALL_OFFERS(code, page, limit)).then(({ data }) => {
  //     if (data) {
  //       setShaveAllOffer(data?.allOffers);
  //       setCount(data.count);
  //     }
  //   });
  // }, [page]);

  const getApproved = (userId, status) => {
    PATCH(API_PATCH_USER_APPROVED(userId, role), { status: status })
      .then(({ data }) => {
        if (data) {
          toast.success("user has been approved");
          setChangeStatus((prev) => !prev);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.msg);
      });
  };
  useEffect(() => {
    GET(API_GET_ALL_CONNECTORS(code, role, page, limit))
      .then(({ data }) => {
        SetAllconnectors(data?.connectors);
        setCount(data.count);
      })
      .catch((err) => {});
  }, [chnageStatus, page]);
  return (
    <>
      <section
        className={`bg-[#F3F6FF] overflow-x-hidden max-h-full rounded-md  ${
          allcConnectors.length < 8 && "pb-16"
        } `}
      >
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full ">
              <div className="max-w-full ">
                <table className="w-full table-auto">
                  <thead className="text-center bg-[#243665]  ">
                    <tr>
                      <th className={TdStyle.ThStyle}> Code</th>
                      <th className={TdStyle.ThStyle}> Date </th>

                      <th className={TdStyle.ThStyle}> Name </th>
                      <th className={TdStyle.ThStyle}> Email </th>
                      <th className={TdStyle.ThStyle}> Phone Number </th>
                      <th className={TdStyle.ThStyle}>Status</th>
                    </tr>
                  </thead>

                  {allcConnectors &&
                    allcConnectors.map((el, index) => {
                      return (
                        <tbody>
                          <tr key={index}>
                            <td className={TdStyle.TdStyle}>{el.code}</td>
                            <td className={TdStyle.TdStyle}>
                              {el?.createdAt.split("T")[0]}
                            </td>

                            <td className={TdStyle.TdStyle}>
                              {el.fname + " " + el.lname}
                            </td>
                            <td className={TdStyle.TdStyle}>{el.email}</td>
                            <td className={TdStyle.TdStyle}>{el.mobileNo}</td>
                            <td className={TdStyle.TdStyle}>
                              {el?.status === "pending" ? (
                                <>
                                  <CHeaderNav className="ms-3">
                                    <CDropdown variant="nav-item">
                                      <CDropdownToggle
                                        placement="bottom-end"
                                        className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-yellow-500`}
                                        caret={false}
                                      >
                                        pending
                                      </CDropdownToggle>
                                      <CDropdownMenu
                                        className="pt-0"
                                        placement="bottom-end"
                                      >
                                        <CDropdownHeader className="bg-light fw-semibold py-2">
                                          status
                                        </CDropdownHeader>
                                        <CDropdownItem
                                          onClick={() =>
                                            getApproved(el?._id, "approved")
                                          }
                                          className="cursor-pointer tex-green-500"
                                        >
                                          Approved
                                        </CDropdownItem>
                                        <CDropdownItem
                                          onClick={() =>
                                            getApproved(el?._id, "reject")
                                          }
                                          className="cursor-pointer text-red-500"
                                        >
                                          Reject
                                        </CDropdownItem>
                                      </CDropdownMenu>
                                    </CDropdown>
                                  </CHeaderNav>
                                </>
                              ) : el.status === "approved" ? (
                                // <div className={TdStyle.TdButton}>{el.status}</div>
                                <CHeaderNav className="ms-3">
                                  <CDropdown variant="nav-item">
                                    <CDropdownToggle
                                      placement="bottom-end"
                                      className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-green-500`}
                                      caret={false}
                                    >
                                      approved
                                    </CDropdownToggle>
                                    <CDropdownMenu
                                      className="pt-0"
                                      placement="bottom-end"
                                    >
                                      <CDropdownHeader className="bg-light fw-semibold py-2">
                                        status
                                      </CDropdownHeader>

                                      <CDropdownItem
                                        onClick={() =>
                                          getApproved(el?._id, "reject")
                                        }
                                        className="cursor-pointer text-red-500"
                                      >
                                        Reject
                                      </CDropdownItem>
                                    </CDropdownMenu>
                                  </CDropdown>
                                </CHeaderNav>
                              ) : (
                                // <div className={TdStyle.TdButton3}>{el.status}</div>
                                <CHeaderNav className="ms-3">
                                  <CDropdown variant="nav-item">
                                    <CDropdownToggle
                                      placement="bottom-end"
                                      className={`${TdStyle.TdButton2} ml-5 !px-6 !py-2 !text-red-500`}
                                      caret={false}
                                    >
                                      Reject
                                    </CDropdownToggle>
                                    <CDropdownMenu
                                      className="pt-0"
                                      placement="bottom-end"
                                    >
                                      <CDropdownHeader className="bg-light fw-semibold py-2">
                                        status
                                      </CDropdownHeader>

                                      <CDropdownItem
                                        onClick={() =>
                                          getApproved(el?._id, "approved")
                                        }
                                        className="cursor-pointer text-red-500"
                                      >
                                        Approved
                                      </CDropdownItem>
                                    </CDropdownMenu>
                                  </CDropdown>
                                </CHeaderNav>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-center bg-[#F3F6FF] py-2 text-xs">
        <div className="  w-fit justify-center border rounded-l-md rounded-r-md  ">
          <button
            disabled={page === 1}
            className={`px-3 py-2 border-x rounded-l-md ${
              page === 1 ? "bg-gray-400" : "hover:bg-blue-400"
            } `}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <button className="px-3 py-2 border-x ">
            {page}
            {` of  ${Math.ceil(count / limit)}`}
          </button>
          <button
            disabled={page === Math.ceil(count / limit)}
            className={`px-3 py-2 border-x rounded-r-md ${
              page === Math.ceil(count / limit)
                ? "bg-gray-400 "
                : "hover:bg-blue-400"
            }`}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AllConnectorsDashboard;
