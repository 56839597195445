import SideBar from "../../components/navigationBar/SideBar";

import ProfileDashboard from "../../components/userdashboard/ProfileDashboard";
const Profile = () => {
  return (
    <div className=" w-screen max-h-screen overflow-hidden bg-gray-200">
      <div className="flex">
        <div className="w-0 md:w-[20%]  max-h-screen bg-[#243665] shadow-md">
          <SideBar />
        </div>
        <div className="w-full md:w-[80%] max-h-screen overflow-y-scroll  mt-20 ">
          {/* <div className="flex justify-end">
        <NavLink to={'/edit-profile'} className=" border border-gray-300 items-end  shadow-md p-2 rounded-md bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none" >Add More</NavLink>

        </div> */}

          <div>
            <ProfileDashboard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
