import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_GET_ALL_APPROVED_CONNECTORS } from "../../utils/APIurls";
import { GET } from "../../utils/webService";

const TdStyle = {
  ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-2 px-3 text-sm font-semibold text-white lg:py-7 lg:px-4`,
  TdStyle: `border-b  border-[#E8E8E8] bg-[#F3F6FF] py-2 px-2 text-center text-xs  font-normal text-dark`,
  TdButton: ` cusor inline-block px-6 py-2 border rounded border-primary text-green-500 `,
  TdButton2: ` cursor-pointer cusor inline-block px-6 py-2 border rounded border-primary text-green-500 `,
};
const ListOfAllOffersDashboard = () => {
  const [AllapprovedConnectors, setAllApprovedConnectors] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const role = sessionStorage.getItem("role");
  const code = sessionStorage.getItem("code");
  const limit = 8;
  useEffect(() => {
    GET(API_GET_ALL_APPROVED_CONNECTORS(code, role, page, limit))
      .then(({ data }) => {
        if (data) {
          setAllApprovedConnectors(data?.connectors);
          setCount(data.count);
        }
      })
      .catch((err) => console.log(err));
  }, [page]);

  const seeDetail = (code) => {
    navigate(`/list-one-user-offer/${code}`);
  };

  return (
    <>
      <section className="bg-gray-200 max-h-full rounded-md">
        <div className="container ">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full ">
              <div className="max-w-full ">
                <table className="w-full table-auto">
                  <thead className="text-center bg-[#243665]">
                    <tr>
                      <th className={TdStyle.ThStyle}> Code</th>
                      <th className={TdStyle.ThStyle}> Date</th>

                      <th className={TdStyle.ThStyle}> Name </th>
                      <th className={TdStyle.ThStyle}> Email </th>
                      <th className={TdStyle.ThStyle}> Phone Number </th>
                      <th className={TdStyle.ThStyle}>Details</th>
                    </tr>
                  </thead>

                  {AllapprovedConnectors &&
                    AllapprovedConnectors?.map((el, index) => {
                      return (
                        <tbody>
                          <tr key={index}>
                            <td className={TdStyle.TdStyle}>{el.code}</td>
                            <td className={TdStyle.TdStyle}>
                              {el?.createdAt.split("T")[0]}
                            </td>
                            <td className={TdStyle.TdStyle}>
                              {el.fname + " " + el.lname}{" "}
                            </td>
                            <td className={TdStyle.TdStyle}>{el.email}</td>
                            <td className={TdStyle.TdStyle}>{el.mobileNo}</td>
                            <td className={TdStyle.TdStyle}>
                              <div
                                type="button"
                                onClick={() => seeDetail(el.code)}
                                className={`${TdStyle.TdButton2} `}
                              >
                                Details
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center bg-[#F3F6FF] py-2 text-xs">
        <div className="  w-fit justify-center border rounded-l-md rounded-r-md  ">
          <button
            disabled={page === 1}
            className={`px-3 py-2 border-x rounded-l-md ${
              page === 1 ? "bg-gray-400" : "hover:bg-blue-400"
            } `}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <button className="px-3 py-2 border-x ">
            {page}
            {` of  ${Math.ceil(count / limit)}`}
          </button>
          <button
            disabled={page === Math.ceil(count / limit)}
            className={`px-3 py-2 border-x rounded-r-md ${
              page === Math.ceil(count / limit)
                ? "bg-gray-400 "
                : "hover:bg-blue-400"
            }`}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ListOfAllOffersDashboard;
