import { useState } from "react";
import AppSidebar from "../../components/admin/AppSidebar";

import AppHeader from "../../components/admin/AppHeader";
import LIstOfOneUserOfferDashboard from "../../components/admin/LIstOfOneUserOfferDashboard";
const ListOfOneUserOffers = () => {
  let [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="w-screen flex">
      <div className={`${openMenu ? "hidden" : "flex w-[20%] "} `}>
        <AppSidebar />
      </div>
      <div
        className={`flex flex-col ${
          openMenu ? "w-full" : " w-[80%]"
        } max-h-screen overflow-hidden `}
      >
        <AppHeader setOpenMenu={setOpenMenu} />
        <div className="p-2">
          <LIstOfOneUserOfferDashboard />
        </div>
      </div>
    </div>
  );
};

export default ListOfOneUserOffers;
