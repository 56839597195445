
import SideBar from "../../components/navigationBar/SideBar";
import HomeDashboard from "../../components/userdashboard/HomeDashboard";
const Home = () => {
  return (
   <div className=" w-screen max-h-screen overflow-hidden bg-gray-200 ">
    <div className="flex">
    <div className=" w-0 md:w-[20%] max-h-screen bg-[#243665] shadow-md">
       <SideBar/>


    </div>
    <div className="w-full md:w-[80%] max-h-screen overflow-y-scroll  mt-20 px-2 md:px-3">
      
      <HomeDashboard/> 

    </div>
   </div>
   </div>

  )
}

export default Home