
import AddOffer from "../../components/formTab/AddOffer"

const Main = () => {
  return (
    <>
     <AddOffer/>
     
    </>
  )
}

export default Main