import { AiFillHome, AiFillFileAdd, AiOutlineLogout } from "react-icons/ai";
import { BiSolidReport, BiSolidUserCircle } from "react-icons/bi";
import Menu from "../navigationBar/Menu";
import { NavLink, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/images/company-logo.svg";
const SideBar = () => {
  const navigate = useNavigate();
  const userName = sessionStorage.getItem("name");
  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("authRole");
    navigate("/user/login");
  };
  return (
    <>
      <div className="overflow-y-auto h-screen  ">
        <div className=" flex flex-col">
          <div className="fixed w-full flex items-center justify-between  h-16  shadow-md bg-gray-100 z-10">
            <div className=" hidden md:flex items-center justify-start border-none">
              <div className="md:ml-20 font-bold font-sans text-violet-400">
                <img
                  src={companyLogo}
                  alt="company-logo"
                  width={70}
                  height={70}
                />
              </div>
            </div>
            <div className=" pl-4 w-full md:w-[80%] flex justify-between items-center h-16 text-[#243665]  bg-gray-100  header-right shadow-md ">
              <div className="flex justify-end gap-3 md:ml-4 text-md">
                <NavLink
                  className="no-underline text-[#243665] font-semibold  hover:text-black"
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  className="no-underline text-[#243665] font-semibold  hover:text-black"
                  to="/profile"
                >
                  Profile
                </NavLink>
                <NavLink
                  className="no-underline text-[#243665] font-semibold  hover:text-black"
                  to="/add-offer"
                >
                  Proposal
                </NavLink>
                <NavLink
                  className="no-underline text-[#243665] font-semibold  hover:text-black"
                  to="/reports"
                >
                  Report
                </NavLink>
              </div>

              <div className="hidden md:flex items-center gap-4 mr-4 ">
                <div>{userName}</div>
                <div>
                  <AiOutlineLogout
                    onClick={logout}
                    size={"24px"}
                    className=" cursor-pointer "
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" hidden md:flex flex-col mt-16  ">
            <Menu url={`/`} icon={<AiFillHome />} title={"Home"} />
            <Menu
              url={"/profile"}
              icon={<BiSolidUserCircle />}
              title={"Profile"}
            />
            <Menu
              url={"/add-offer"}
              icon={<AiFillFileAdd />}
              title={"Add Proposal"}
            />
            <Menu url={"/reports"} icon={<BiSolidReport />} title={"Reports"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
