
import SideBar from "../../components/navigationBar/SideBar";
import OfferDashboard from "../../components/userdashboard/OfferDashboard";
const AddOffer = () => {
  return (
   <div className=" w-screen max-h-screen overflow-hidden  ">
    <div className="flex">
    <div className="w-0 md:w-[20%] max-h-screen bg-[#243665] shadow-md">
       <SideBar/>
    </div>
    <div className="w-full md:w-[80%] mt-16 max-h-screen overflow-y-scroll md:px-3 ">
       
      
        <OfferDashboard/>
   
      
    </div>
   
   </div>
   </div>

  )
}

export default AddOffer